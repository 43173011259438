import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import AvailableJobDialogContent from './AvailableJobDialogContent';
import CheckedOutJobDialogContent from './CheckedOutJobDialogContent';
import ScanQRCodeDialogContent from './ScanQRCodeDialogContent';
import ChangeStateDialogContent from './ChangeStateDialogContent';
import { useGetTask } from '../../hooks/useTasks';
import { SiteContext } from '../Contexts/SiteContext';

function JobDialog({ job, show, closeDialog, updateJob, valves }) {

    JobDialog.propTypes = {
        job: PropTypes.object,
        show: PropTypes.bool,
        closeDialog: PropTypes.func,
        updateJob: PropTypes.func,
        valves: PropTypes.object
    }

    const { selectedSite } = useContext(SiteContext);
    const { task } = useGetTask(selectedSite, job.taskName);

    // Set the first page the dialog should load, based on state
    let defaultStartPage = 0;
    if (job.state === "Available") {
        defaultStartPage = 1;
    }
    else if (job.state === "CheckedOut") {
        defaultStartPage = 2;
    }

    const [currentPage, setCurrentPage] = useState(defaultStartPage);

    // Move on to the next page
    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    }

    // Render the content required for the page
    const getContent = (currentPage) => {
        switch (currentPage) {
            case 1:
                return <AvailableJobDialogContent job={job} nextPage={nextPage} updateJob={updateJob} closeDialog={closeDialog} />
            case 2:
                return <CheckedOutJobDialogContent job={job} nextPage={nextPage} updateJob={updateJob} closeDialog={closeDialog} />
            case 3:
                return <ScanQRCodeDialogContent job={job} nextPage={nextPage} updateJob={updateJob} closeDialog={closeDialog} valves={valves} />
            case 4:
                return <ChangeStateDialogContent job={job} updateJob={updateJob} closeDialog={closeDialog} />
            default:
                console.log(`Dialog page ${currentPage} does not exist`);
                closeDialog();
                break;
        }
    }

    return (
        <Modal centered show={show} onHide={closeDialog} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{job.valveName} ({job.taskType })
                    <h6 className='mt-2'>{job.valveDescription}</h6>
                    <h6 className='mt-2'>{job.description}</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getContent(currentPage)}
            </Modal.Body>
        </Modal>
    );
}

export default JobDialog;